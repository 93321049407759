const refundPolicyURL = 'https://www.fixdapp.com/returns-warranty'
const privacyPolicyURL = 'https://www.fixdapp.com/privacy'
const termsOfServiceURL = 'https://www.fixdapp.com/terms-of-service'
const subscriptionPolicyURL = 'https://www.fixdapp.com/returns-warranty'
const supportURL = 'https://www.fixd.com/help'
const careersURL = 'https://angel.co/company/fixd-automotive'
const newSupportTicketURL = 'https://www.fixd.com/help'
const returnPolicyURL = 'https://www.fixdapp.com/returns-warranty'
const aboutPremiumURL = 'https://get.fixdapp.com/premium/home' // TODO: this probably won't be around forever

const social = {
  facebookURL: 'https://www.facebook.com/fixdapp',
  youtubeURL: 'https://www.youtube.com/fixdapp',
  instagramURL: 'https://www.instagram.com/fixd_automotive/',
  twitterURL: 'https://twitter.com/fixd_app',
  tiktokURL: 'https://www.tiktok.com/@fixd',
}

export {
  refundPolicyURL,
  privacyPolicyURL,
  termsOfServiceURL,
  subscriptionPolicyURL,
  supportURL,
  careersURL,
  newSupportTicketURL,
  aboutPremiumURL,
  social,
  returnPolicyURL,
}
